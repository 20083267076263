import React, { useState, useEffect } from "react"
import qs from "qs"
import { useStoreState } from "easy-peasy"
import { navigate, useLocation } from "@reach/router"
import Layout from "@layouts/MainLayout"
import Container from "@layouts/Container"
import { Box, ErrorAlert } from "@lib/ui/core"
import ExerciseProcess from "@components/Exercise/Process"

import { getQuiz, startQuiz } from "@services/exercise"

import Spinner from "@components/Spinner"

const ProcessPage = () => {
  const [alert, setAlert] = useState(false)
  const [content, setContent] = useState(null)
  const [isQuiz, setIsQuiz] = useState(false)

  const closeAlert = () => {
    setAlert(false)
  }

  const accessToken = useStoreState(
    ({ localStorage }) => localStorage.auth.accessToken
  )

  const location = useLocation()

  const fetchData = async ({ id }) => {
    try {
      let data = await getQuiz({ id })

      if (data.item.questions_count === 0) {
        setAlert(true)
      } else {
        if (!data.item.start_time) {
          await startQuiz({ id })
          data = await getQuiz({ id })
        }
        setIsQuiz(data.item.end_time === null)
        setContent(data.item)
      }
    } catch (e) {
      setAlert(true)
    }
  }
  useEffect(() => {
    if (!accessToken) navigate("/")
    const { search } = location
    const searchParams = qs.parse(search.substring(1))
    const quizId = searchParams.quiz_id
    fetchData({ id: quizId })
  }, [])

  useEffect(() => {
    if (isQuiz) {
      const onUnload = (e) => {
        e.preventDefault()
        e.returnValue = ""
      }
      window.addEventListener("beforeunload", onUnload)
      return () => window.removeEventListener("beforeunload", onUnload)
    }
  }, [isQuiz])

  return (
    <Layout isQuiz={isQuiz} hideFooter>
      <Box>
        <Container>
          {content ? (
            <ExerciseProcess content={content} accessToken={accessToken} />
          ) : (
            <Box pt={10} display="flex" justifyContent="center">
              <Spinner />
            </Box>
          )}
        </Container>
      </Box>
      <ErrorAlert open={alert} handleClose={closeAlert}>
        系統出錯，請稍後再試。如問題持續，請聯絡客服。
      </ErrorAlert>
    </Layout>
  )
}

export default ProcessPage
