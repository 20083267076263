import React, { useState, useEffect } from "react"

import { Box, Typography } from "@lib/ui/core"

const Timer = ({ onTimeOut, minuteLeft, secondLeft }) => {
  const [minutes, setMinutes] = useState(minuteLeft)
  const [seconds, setSeconds] = useState(secondLeft)

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      if (seconds === 0) {
        if (minutes === 0) {
          onTimeOut()
        } else {
          setMinutes(minutes - 1)
          setSeconds(59)
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval)
    }
  })

  return (
    <div>
      {minutes === 0 && seconds === 0 ? null : (
        <Box color="#fff">
          <Typography variant="subtitle1">
            {minutes < 10 ? `0${minutes}` : minutes}:
            {seconds < 10 ? `0${seconds}` : seconds}
          </Typography>
        </Box>
      )}
    </div>
  )
}

export default Timer
