import React, { useState } from "react"
import styled from "styled-components"
import {
  Box,
  Button,
  Typography,
  DialogWithCloseButton,
  TextField,
} from "@lib/ui/core"

import { reportQuestion } from "@services/exercise"

const CancelButton = styled(Button)`
  background-color: #808080;
  &:hover {
    background-color: #808080;
  }
`

// eslint-disable-next-line arrow-body-style
const ReportModal = ({ id, visible, handleClose, onReportQuestion }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [remarks, setRemarks] = useState("")

  const onReport = async () => {
    setIsLoading(true)
    try {
      onReportQuestion({ id, remarks })
      handleClose()
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <Box>
      <DialogWithCloseButton
        onClose={() => {
          if (!isLoading) {
            handleClose()
          }
        }}
        open={visible}
        title="回報問題"
      >
        <Box p={1}>
          <TextField
            label="問題簡述"
            onChange={(e) => setRemarks(e.target.value)}
          />
        </Box>
        <Box p={1} display="flex" justifyContent="flex-end">
          <Box>
            <CancelButton mr={1} disabled={isLoading} onClick={handleClose}>
              <Typography color="textSecondary">取消</Typography>
            </CancelButton>
          </Box>
          <Box>
            <Button
              disabled={isLoading}
              onClick={() => {
                onReport()
              }}
            >
              <Typography color="textSecondary">確認</Typography>
            </Button>
          </Box>
        </Box>
      </DialogWithCloseButton>
    </Box>
  )
}

export default ReportModal
