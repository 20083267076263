import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { navigate } from "@reach/router"
import { Box, AppBar, Toolbar, Button, SvgIcon, TextField } from "@lib/ui/core"

import { endQuiz } from "@services/exercise"

import { ReactComponent as ClockIcon } from "@assets/icons/clock.svg"

import Timer from "./Timer"

const BottomAppBar = styled(AppBar)`
  bottom: 0;
  top: auto;
  padding 0 5rem;
`

const StyledButton = styled(Button)`
  background-color: #fff !important;
  padding: 0.25rem 2rem;
  border-radius: 14px;
  color: #007bff !important;
`

const BottomBar = ({ mode, duration, id, endTime, startTime }) => {
  const submitQuiz = async () => {
    await endQuiz({ id })
    navigate(`/exercise/summary?quiz_id=${id}`)
  }

  const buttonText = {
    single_topic: "完成練習",
    multiple_topic: "完成練習",
    quiz: "遞交測驗",
    exam: "遞交試卷",
  }

  const expectedEndDate = new Date(`${startTime.replace(/ /g, "T")}Z`)
  // expectedEndDate.setHours(expectedEndDate.getHours() + 8)
  expectedEndDate.setSeconds(expectedEndDate.getSeconds() + duration * 60)
  const now = new Date()
  now.setHours(now.getHours() + 8)
  const totalSecondLeft = Math.round((expectedEndDate - now) / 1000)
  const minuteLeft = Math.floor(totalSecondLeft / 60)
  const secondLeft = totalSecondLeft % 60

  return (
    <BottomAppBar position="fixed" bgcolor="#2F80ED">
      <Toolbar>
        {(mode === "quiz" || mode === "exam") && !endTime && (
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center" px={1}>
              <SvgIcon component={ClockIcon} fontSize="1.5rem" />
            </Box>
            <Timer
              duration={duration}
              onTimeOut={submitQuiz}
              startTime={startTime}
              minuteLeft={minuteLeft > 0 ? minuteLeft : 0}
              secondLeft={minuteLeft >= 0 ? secondLeft : 0}
            />
          </Box>
        )}
        <Box
          position="absolute"
          display="flex"
          justifyContent="center"
          width="100%"
        >
          {!endTime ? (
            <StyledButton onClick={() => submitQuiz()}>
              {buttonText[mode]}
            </StyledButton>
          ) : (
            <StyledButton
              onClick={() => navigate(`/exercise/summary?quiz_id=${id}`)}
            >
              前往總結
            </StyledButton>
          )}
        </Box>
      </Toolbar>
    </BottomAppBar>
  )
}

export default BottomBar
