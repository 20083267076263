import React, { useState } from "react"
import styled from "styled-components"
import Imgix from "react-imgix"
import {
  Box,
  DialogWithCloseButton,
  TextField,
  Button,
  Typography,
  SvgIcon,
  Grid,
  Select,
  MenuItem,
  Chip,
  AppBar,
  Toolbar,
  Pagination,
  PaginationItem,
} from "@lib/ui/core"

import { ReactComponent as StarIcon } from "@assets/icons/star.svg"
import { ReactComponent as TickIcon } from "@assets/icons/tick.svg"
import { ReactComponent as CrossIcon } from "@assets/icons/cross.svg"

import { checkAns, bookmarkQuestion, reportQuestion } from "@services/exercise"

import BottomBar from "./BottomBar"
import ReportModal from "./ReportModal"

const CircleChip = styled(Box)`
  display: flex;
  background-color: ${({ selected }) => (selected ? "#0F1D65" : "#cecece")};
  color: ${({ selected }) => (selected ? "#ffffff" : "#4d4d4d")};
  padding: 8px;
  font-size: 20px;
  border-radius: 50%;
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  justify-content: center;
  align-items: center;
`

const SolvedIcon = styled(Box)`
  display: flex;
  background-color: #0f1d65;
  color: #fff;
  padding: 8px;
  font-size: 20px;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  justify-content: center;
  align-items: center;
`

const StyledButton = styled(Button)`
  background-color: #007bff !important;
  padding: 0 1rem;
  border-radius: 14px;
  color: #fff !important;
`

const SolvedText = styled(Box)`
  display: inline-block;
  background-color: ${({ bgcolor }) => bgcolor};
  border-radius: 40px 0px 40px 40px;
  color: #fff;
  padding: 4px 24px 4px 4px;
`

const StyledPaginationItem = styled(PaginationItem)`
  background-color: ${({ light, type }) =>
    type === "page" && light ? "#81c0ff !important" : "#E6E6E6"};
  color: ${({ light, type }) =>
    type === "page" && light ? "#fff" : "#4d4d4d"};
  margin: 6px;
`

const StyledPagination = styled(Pagination)`
  .MuiPagination-ul {
    flex-wrap: nowrap;
  }
`

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7.5px 13px 7.5px;
  border-color: transparent transparent #81c0ff transparent;
  position: relative;
  top: 16px;
  margin-top: -16px;
`

const BlueBar = styled.div`
  background-color: #81c0ff;
  height: 5px;
  width: 100%;
  border-radius: 5px;
`

const RedCircle = styled(Box)`
  border: 2px;
  border-style: solid;
  border-color: ${({ correct }) => (correct ? "#CF2929" : "#fff")};
  border-radius: 100% 25% 100% 25%;
`

const ImageContainer = styled(Box)`
  image-rendering: -webkit-optimize-contrast;
`

// eslint-disable-next-line arrow-body-style
const Process = ({ content: orginalContent }) => {
  const [content, setContent] = useState(orginalContent)
  const [openReportModal, setOpenReportModal] = useState(false)
  console.log(content)
  const {
    questions_count,
    questions,
    duration,
    start_time: startTime,
    end_time: endTime,
    mode,
  } = content
  const [page, setPage] = useState(1)
  const [currentQuestion, setCurrentQuestion] = useState(questions[page - 1])
  console.log(questions)
  console.log(currentQuestion)
  const {
    user_ans: userAnswer,
    is_correct: correct,
    question_id: questionId,
    is_skip: skipped,
    is_report_bug: reported,
  } = currentQuestion
  const answered = userAnswer || skipped
  const {
    is_bookmarked: bookmarked,
    ans_choice: correctAnswer,
  } = currentQuestion.data
  const [currentSelectedAnwser, setCurrentSelectedAnswer] = useState("NA")
  const handlePageChange = (e, newPage) => {
    setPage(newPage)
    setCurrentQuestion(questions[newPage - 1])
    setCurrentSelectedAnswer("NA")
  }

  const nextPage = () => {
    setPage(page + 1)
    setCurrentQuestion(questions[page])
    setCurrentSelectedAnswer("NA")
  }

  const closeReportModal = () => {
    setOpenReportModal(false)
  }

  const onReportQuestion = async ({ id, remarks }) => {
    const data = await reportQuestion({
      id,
      remarks,
    })
    const newContent = { ...content }
    newContent.questions[page - 1].is_report_bug = data.item.is_report_bug
    setCurrentQuestion({ ...newContent.questions[page - 1] })
    setContent(newContent)
    setOpenReportModal(false)
  }

  const submitAnswer = async () => {
    try {
      const data = await checkAns({
        quizId: content.id,
        questionId: currentQuestion.id,
        answer: currentSelectedAnwser,
        skip: 0,
      })
      const newContent = { ...content }
      newContent.questions[page - 1] = data.item
      setContent(newContent)
      setCurrentQuestion(data.item)
      if ((mode === "quiz" || mode === "exam") && page !== questions_count) {
        nextPage()
      }
    } catch (e) {
      console.log(e)
    }
  }

  const skip = async () => {
    const data = await checkAns({
      quizId: content.id,
      questionId: currentQuestion.id,
      answer: "NA",
      skip: 1,
    })
    const newContent = { ...content }
    newContent.questions[page - 1] = data.item
    setContent(newContent)
    setCurrentQuestion(data.item)
    if (page !== questions_count) {
      nextPage()
    }
  }

  const bookmark = async () => {
    const data = await bookmarkQuestion({
      id: questionId,
    })
    const newContent = { ...content }
    newContent.questions[page - 1].data.is_bookmarked = data.bookmarked
    setContent(newContent)
    setCurrentQuestion({ ...newContent.questions[page - 1] })
  }

  return (
    <Box p={1} mb={8}>
      <Box>
        <Box pt={2}>
          <StyledPagination
            count={questions_count}
            siblingCount={9}
            page={page}
            onChange={handlePageChange}
            renderItem={(item) => (
              <Box>
                {item.type === "page" &&
                  questions[item.page - 1] &&
                  questions[item.page - 1].data.is_bookmarked && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      position="relative"
                      bottom="16px"
                      marginBottom="-16px"
                    >
                      <SvgIcon
                        component={StarIcon}
                        fontSize="1rem"
                        color="#ffca5e"
                      />
                    </Box>
                  )}
                <StyledPaginationItem
                  {...item}
                  light={
                    questions[item.page - 1] &&
                    questions[item.page - 1].user_ans &&
                    questions[item.page - 1].is_skip === false
                  }
                />
                {item.selected && (
                  <Box display="flex" justifyContent="center">
                    <Triangle />
                  </Box>
                )}
              </Box>
            )}
          />
        </Box>
        <Box pt={1}>
          <BlueBar />
        </Box>
      </Box>
      <ImageContainer py={1}>
        <Imgix src={currentQuestion.data.q_img.url} width="100%" />
        {/* {currentQuestion.data.q_img.url} */}
      </ImageContainer>
      {!reported && (
        <Box width="100%" display="flex" justifyContent="flex-end">
          <StyledButton onClick={() => setOpenReportModal(true)} width="6rem">
            回報問題
          </StyledButton>
        </Box>
      )}
      {currentQuestion.data.type === "mc" ? (
        <Box py={1}>
          <Typography>選擇答案</Typography>
          <Box display="flex" alignItems="center" py={1}>
            <RedCircle
              correct={
                !correct &&
                correctAnswer === "A" &&
                (endTime ||
                  (!skipped &&
                    (mode === "single_topic" || mode === "multiple_topic")))
              }
              mr={1}
              p={1}
            >
              <CircleChip
                align="center"
                onClick={() => setCurrentSelectedAnswer("A")}
                selected={
                  answered ? answered === "A" : currentSelectedAnwser === "A"
                }
              >
                A
              </CircleChip>
            </RedCircle>
            <RedCircle
              correct={
                !correct &&
                correctAnswer === "B" &&
                (endTime ||
                  (!skipped &&
                    (mode === "single_topic" || mode === "multiple_topic")))
              }
              mr={1}
              p={1}
            >
              <CircleChip
                fontSize="12px"
                align="center"
                onClick={() => setCurrentSelectedAnswer("B")}
                selected={
                  answered ? answered === "B" : currentSelectedAnwser === "B"
                }
              >
                B
              </CircleChip>
            </RedCircle>
            <RedCircle
              correct={
                !correct &&
                correctAnswer === "C" &&
                (endTime ||
                  (!skipped &&
                    (mode === "single_topic" || mode === "multiple_topic")))
              }
              mr={1}
              p={1}
            >
              <CircleChip
                fontSize="12px"
                align="center"
                onClick={() => setCurrentSelectedAnswer("C")}
                selected={
                  answered ? answered === "C" : currentSelectedAnwser === "C"
                }
              >
                C
              </CircleChip>
            </RedCircle>
            <RedCircle
              correct={
                !correct &&
                correctAnswer === "D" &&
                (endTime ||
                  (!skipped &&
                    (mode === "single_topic" || mode === "multiple_topic")))
              }
              mr={1}
              p={1}
            >
              <CircleChip
                fontSize="12px"
                align="center"
                onClick={() => setCurrentSelectedAnswer("D")}
                selected={
                  answered ? answered === "D" : currentSelectedAnwser === "D"
                }
              >
                D
              </CircleChip>
            </RedCircle>
            <Box px={1}>
              <SvgIcon
                component={StarIcon}
                fontSize="1.875rem"
                color={bookmarked ? "#ffca5e" : "#cecece"}
                style={{
                  cursor: "pointer",
                }}
                onClick={() => bookmark()}
              />
            </Box>
          </Box>
          {!answered ? (
            <Box display="flex" py={1}>
              <Box pr={1}>
                <StyledButton onClick={() => submitAnswer()} width="6rem">
                  確認答案
                </StyledButton>
              </Box>
              {page !== questions_count && (
                <Box>
                  <StyledButton onClick={() => nextPage()} width="6rem">
                    略過
                  </StyledButton>
                </Box>
              )}
            </Box>
          ) : (
            <Box>
              {(endTime ||
                (mode === "single_topic" && !skipped) ||
                (mode === "multiple_topic" && !skipped)) && (
                <Box>
                  {correct ? (
                    <Box py={1}>
                      <SolvedText bgcolor="#68e8c5">
                        <Box display="flex" alignItems="center">
                          <Box pr={1} display="flex" alignItems="center">
                            <SvgIcon
                              component={TickIcon}
                              viewBox="0 0 28 28"
                              currentColor="#fff"
                            />
                          </Box>
                          你答對了
                        </Box>
                      </SolvedText>
                    </Box>
                  ) : (
                    <Box py={1}>
                      <SolvedText bgcolor="#cf2929">
                        <Box display="flex" alignItems="center">
                          <Box pr={1} display="flex" alignItems="center">
                            <SvgIcon
                              component={CrossIcon}
                              viewBox="0 0 28 28"
                              currentColor="#fff"
                            />
                          </Box>
                          你答錯了
                        </Box>
                      </SolvedText>
                    </Box>
                  )}
                </Box>
              )}
              {(endTime ||
                (mode === "single_topic" && !skipped) ||
                (mode === "multiple_topic" && !skipped)) && (
                <Box>
                  <SolvedIcon my={1}>解</SolvedIcon>
                  <ImageContainer py={1}>
                    <Imgix
                      src={currentQuestion.data.ans_imgs[0].url}
                      width="100%"
                    />
                  </ImageContainer>
                </Box>
              )}
              {page !== questions_count && (
                <Box>
                  <StyledButton width="6rem" onClick={() => nextPage()}>
                    下一題
                  </StyledButton>
                </Box>
              )}
            </Box>
          )}
        </Box>
      ) : (
        <Box>
          <Box>
            <SvgIcon
              component={StarIcon}
              fontSize="1.875rem"
              color={bookmarked ? "#ffca5e" : "#cecece"}
              style={{
                cursor: "pointer",
              }}
              onClick={() => bookmark()}
            />
          </Box>
          {!answered ? (
            <Box display="flex" py={1}>
              <Box pr={1}>
                <StyledButton onClick={() => submitAnswer()} width="6rem">
                  確認答案
                </StyledButton>
              </Box>
              {page !== questions_count && (
                <Box>
                  <StyledButton onClick={() => nextPage()} width="6rem">
                    略過
                  </StyledButton>
                </Box>
              )}
            </Box>
          ) : (
            <Box>
              {(endTime ||
                (mode === "single_topic" && !skipped) ||
                (mode === "multiple_topic" && !skipped)) && (
                <Box>
                  <SolvedIcon my={1}>解</SolvedIcon>
                  <ImageContainer py={1}>
                    <Imgix
                      src={currentQuestion.data.ans_imgs[0].url}
                      width="100%"
                    />
                  </ImageContainer>
                </Box>
              )}
              {page !== questions_count && (
                <Box>
                  <StyledButton width="6rem" onClick={() => nextPage()}>
                    下一題
                  </StyledButton>
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
      <ReportModal
        visible={openReportModal}
        onReportQuestion={onReportQuestion}
        handleClose={closeReportModal}
        id={currentQuestion.id}
      />
      <BottomBar
        id={content.id}
        duration={duration}
        startTime={startTime}
        endTime={endTime}
        mode={mode}
      />
    </Box>
  )
}

export default Process
